<template>
  <div class="config-integrations">
    <h1 class="titulo-card fs-4">Integrações</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>
      <div class="row g-0">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" aria-current="page" href="#" data-bs-toggle="tab" data-bs-target="#zabbix-integration" type="button" role="tab" aria-controls="zabbix-integration" aria-selected="true">Zabbix</a>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <a class="nav-link disabled" aria-current="page" href="#" data-bs-toggle="tab" data-bs-target="#chatbot-integrations" type="button" role="tab" aria-controls="chatbot-integrations" aria-selected="true">Chatbot</a>
          </li> -->
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane active" id="zabbix-integration" role="tabpanel" aria-labelledby="zabbix-integration-tab">
            <div class="row g-0">
              <ZabbixIntegration/>
            </div>
          </div>
          <div class="tab-pane" id="chatbot-integrations" role="tabpanel" aria-labelledby="chatbot-integrations-tab">
            <div class="row g-0">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZabbixIntegration from '../components/ZabbixIntegration.vue';

export default {
  name: 'ConfigIntegrations',
  components: {
    ZabbixIntegration,
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
}
</script>
