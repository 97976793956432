<template>
  <div class="zabbix-integration">
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="addBtnClick()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>

        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="searchBtnClick()"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="searchBtnClick()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th width="120">Tipo</th>
              <th>Descrição</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="integration in integrationList" :key="integration.id" :class="css(integration)">
                <td scope="row">{{integration.description}}</td>
                <td>{{integration.endpoint}}</td>
                <td>
                  <button v-on:click="tableItemClick(integration)" class="btn btn-sm btn-outline-primary float-end" type="button">
                    Ver
                    <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width class="ms-1"/>
                  </button>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import loading from '@/modules/loading';
import dialogo from '@/modules/dialogo';
import Paginador from '@/components/Paginador.vue'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: "ZabbixIntegration",
  components: {
    Paginador,
  },
  data() {
    return {
      integrationList: [],
      paginador: clone(model.paginador),
    }
  },
  methods: {
    async search(silent) {
      if (!silent) {
        loading(true);
      }

      await api.get(`/integrations/zabbix?${this.paginador.requestParams}&orderBy[description]`).then(res => {
        this.integrationList = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        if (!silent) {
          dialogo.erroHttp(error);
        }
      });

      if (!silent) {
        loading(false);
      }
    },

    css(integration) {
      if (!integration.active) {
        return ['text-muted'];
      }

      return ['text-info'];
    },

    view(integration) {
      this.$router.push('/configuracoes/integracoes/zabbix/'+ integration.id);
    },

    //////////////////////////////////////
    // click handlers
    addBtnClick() {
      this.$router.push('/configuracoes/integracoes/zabbix/adicionar');
    },

    searchBtnClick() {
      this.search();
    },

    onChangePaginacao() {
      this.search();
    },

    tableItemClick(integration) {
      this.view(integration);
    },
    // click handlers
    //////////////////////////////////////
  },
  created() {
    this.search(true);
  },
};
</script>
